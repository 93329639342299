import React from "react";
import styles from "./styles/Home.module.scss";

function Home() {
	const artist_name = "heheheyupp";
	return (
		<div className={styles.Home}>
			<iframe
				className={styles.embed}
				style={{ border: "12px" } as React.CSSProperties} // Указываем стиль и типизируем его
				src="https://open.spotify.com/embed/artist/341FEmk5vK4ytbbGLQ7lhC?utm_source=generator&theme=0"
				title="Spotify"
				allowFullScreen
				allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
				loading="lazy"
			></iframe>
			<iframe
				style={{ border: "12px" } as React.CSSProperties} // Указываем стиль и типизируем его
				className={styles.embed}
				allow="clipboard-write"
				src="https://music.yandex.ru/iframe/album/33938020"
			>
				Слушайте{" "}
				<a href="https://music.yandex.ru/album/33938020">
					Диалог о взаимопонимании
				</a>{" "}
				— <a href="https://music.yandex.ru/artist/21904111">heheheyup</a> на
				Яндекс Музыке
			</iframe>
			<iframe
				className={styles.embed}
				width="400"
				allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
				height="450"
				style={{ maxWidth: "660px", overflow: "hidden", borderRadius: "10px" }}
				sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
				src="https://embed.music.apple.com/ru/album/%D0%B4%D0%B8%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BE-%D0%B2%D0%B7%D0%B0%D0%B8%D0%BC%D0%BE%D0%BF%D0%BE%D0%BD%D0%B8%D0%BC%D0%B0%D0%BD%D0%B8%D0%B8-feat-amnesiawho-single/1778388422"
			></iframe>

			
		</div>
	);
}

export default Home;
