import styles from "./styles/LinkButton.module.scss";
import React, { ReactNode } from "react";

interface LinkButtonProps {
	platformName: string; // Название площадки
	Icon: ReactNode; // SVG-компонент для иконки
	link: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({
	platformName,
	Icon,
	link,
}) => {
	const handleClick = () => {
		window.location.href = link;
	};
	return (
		<li
			className={styles.LinkButton}
			onClick={() => {
				handleClick();
			}}
		>
			<div className={styles.avatar}>{Icon}</div>

			<p className={`${styles.platformName} platformName`}>{platformName}</p>
		</li>
	);
};

export default LinkButton;
