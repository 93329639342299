import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Home from "./pages/Home/Home";
import reportWebVitals from './reportWebVitals';
import Menu from "./components/Menu/Menu";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<Menu/>
		<Home />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

